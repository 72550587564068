import { graphql } from 'gatsby'
import React from 'react'
import Layout from 'components/shared/Layout'
import SEO from 'components/shared/SEO'
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader'
import DefaultMDXRenderer from 'components/shared/DefaultMDXRenderer'
deckDeckGoHighlightElement()

const ShortDiaryTemplate = ({ location, data }) => {
  const { mdx: shortDiary } = data

  const title = shortDiary.frontmatter.title
  const description = shortDiary.frontmatter.excerpt

  return (
    <>
      <SEO title={title} description={description} article />

      <Layout location={location}>
        <div className='wrapper'>
          <header className='py-14'>
            <h1 className='text-3xl md:text-4xl font-bold mb-4'>{title}</h1>

            <p className='text-gray-500 text-sm'>
              {shortDiary.frontmatter.createdAt}
            </p>

            <div className='flex mt-4 flex-wrap'>
              {shortDiary.frontmatter.tags.map((tag) => (
                <div
                  key={tag}
                  className='text-primary-darken-50 text-xs border border-primary-darken rounded-lg px-2 mb-2 mr-2 bg-white'
                >
                  {tag.replace(/-/g, ' ')}
                </div>
              ))}
            </div>
          </header>

          <div>
            <DefaultMDXRenderer>{shortDiary.body}</DefaultMDXRenderer>
          </div>
        </div>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query ShortDiaryByPath($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      body
      excerpt
      frontmatter {
        title
        path
        tags
        createdAt(formatString: "YYYY년 MM월 DD일")
      }
    }
  }
`

export default ShortDiaryTemplate
